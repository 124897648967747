import React, { useState, useEffect, useContext } from "react";
import { gsap } from "gsap";
import { FaTimes } from "react-icons/fa";
import Row  from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col"
import {Image, Transformation, Placeholder} from 'cloudinary-react'
import { AuthContext } from '../firebase'
import { navigate } from 'gatsby-link';

const DoctorModal = ({doctor, visible, close}) => {
  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser

  let modalVeil = null;
  let modalDialog = null;
  let modalContent = null;
  let modalCta = null

  const [modalTween] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    modalTween
      .to(modalVeil, 0.25, { autoAlpha: 1 })
      .to(modalDialog, 0.35, { y: 0, autoAlpha: 1 })
      .from(
        modalContent.children,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
        )
        .from(
          "#close1", 
          0.35, 
          { y: 15, opacity: 0 }
        )
        .from(modalCta.children, 0.35, {y: 15, opacity: 0, ease: "back", stagger: 0.1})
        .reverse();
  }, [modalVeil, modalDialog, modalTween, modalCta, modalContent, visible]);

  useEffect(() => {
    modalTween.reversed(!visible);
  }, [visible, modalTween]);

  const closeModal = () => {
    modalTween.reverse();
    gsap.delayedCall(modalTween.duration(), close);
  };

  const handleAppointment = () =>{

    user
    ?
      navigate("/app/scheduler", {state:{doctor},})
    :
      navigate("/signin")
  }


  return (
    <div className={`modal-container ${visible ? " show" : ""}`}>
      <div className="modal-veil" role="button" tabIndex={0} ref={e => (modalVeil = e)} onClick={closeModal} onKeyDown={closeModal}>
        <FaTimes id="close1" className="close white-text" />
      </div>
      <div className="doctor-modal-dialog white" ref={e => (modalDialog = e)}>
        <Row>
          <Col l={5}>
            <Image cloudName="bizhightech" publicId={doctor.img.public_id} className="doctor-modal-image hide-on-med-and-down">
              <Placeholder type="blur" />
              <Transformation width="400" height="620" gravity="faces" crop="fill" />
              <Transformation quality="auto" fetchFormat="auto" />
              <Transformation overlay="allodoc" gravity="north_east" x="45" y="15" width="50" opacity="40" effect="brightness:200" />
            </Image>
          </Col>
          <Col l={7} m={12} s={12}>
            <div className="doctor-modal-info" ref={e => (modalContent = e)}>
              <h3 className="color-4 doctor-modal-name">{doctor.isDoctor && <span>Dr </span>}{`${doctor.firstname} ${doctor.lastname}`}{(doctor.title && (doctor.title !== "")) && <span>, {doctor.title}</span>}</h3>
              <p className="doctor-modal-spec color-1"><strong>{doctor.spec}</strong></p>
              <em className="doctor-modal-exp color-1"><strong>{doctor.experience}</strong> { (doctor.experience > 1) ? "années d'expériences" : "année d'expérience"}</em>
              <h6 className="color-1 doctor-modal-summary-head">Résumé</h6>
              <div className="doctor-modal-summary-contain color-1">
                {doctor.summary}
              </div>
            </div>
            <div className="doctor-modal-cta" ref={e => (modalCta = e)}>
              <button className="mybtn color-3 color-0-bg left" onClick={handleAppointment}>Prendre Rendez-vous</button>
              <button className="mybtn color-0 color-3-bg right" onClick={closeModal}>Fermer</button>
            </div>
          </Col>
        </Row>
        {
          // doctor.isDoctor
          // ?
          // <CompleteDoctor complete={complete} doctor={doctor} ref={e => (modalContent = e)} close={closeModal} />
          // :
          // <CompletePatient complete={complete} doctor={doctor} ref={e => (modalContent = e)} close={closeModal} />   
        }
      </div>
    </div>
  )
}

export default DoctorModal