import React, {useRef, useEffect} from "react"
import { gsap } from 'gsap'
import {Formik, Form, Field} from 'formik'
import Search from "../UI/Form/search";


const HeaderDocs = () =>{

  let text = [useRef(null), useRef(null)]

  useEffect(() => {
    gsap.from(text, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .2, stagger: 0.1})
  }, [])

  return (
    <header className="header-pages center">
      <div className="header-pages-text">
        {/* <h3 ref={el => text[0] = el} className="center white-text">&Agrave; propos</h3> */}
        <p ref={el => text[0] = el} className="color-1">Cherchez le m&eacute;decin ou la specialit&eacute; dont vous avez besoin ici, ou parcourez la liste plus bas o&ugrave; tous les m&eacute;decins sont affich&eacute;s.</p>
        <div className="createlesson-form">
            <Formik
              initialValues={{
                category: '',
                title: '',
                summary: '',
                url: '',
                body: ''
              }}
              // validationSchema={CreateSchema}
              onSubmit={(values, actions) =>{
                // createLesson(values)
                actions.resetForm()
              }}
            >
              {({isSubmitting, isValid, handleReset}) => (
                <Form className="lesson-form" ref={el => text[1] = el}>
                  <Field
                    type='text'
                    name='title'
                    placeholder='Rechercher médecins ...'
                    component={Search}
                  />
                  
                </Form>           
              )}
            </Formik>
          </div>
      </div>      
    </header>
  )
}
;
export default HeaderDocs;