import React, {useRef, useEffect} from 'react';
// import styled from 'styled-components'
import gsap from 'gsap'
import { FaSearch } from 'react-icons/fa';



const Search = ({field, form:{touched, errors}, ...props}) => {
  let errorEl = useRef(null)

  useEffect(() => {
    // (errors[field.name] && touched[field.name]) && gsap.from(errorEl, {duration: 1.2, y: -20, ease: "back"})
  },)

  return (
  <div className="search-wrapper">   
    <input className="styled-search" {...field} {...props}>{props.children}</input>
    <button className="search-btn" type="submit"><FaSearch /></button>
    {/* <div className="input-error" ref={el => errorEl = el} >{errors[field.name]}</div> */}
  </div>
  )
};

export default Search;