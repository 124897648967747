import React, {useState, useContext} from 'react';
import {Image, Transformation, Placeholder} from 'cloudinary-react'
import DoctorModal from '../app/doctorModal';
import { AuthContext } from '../firebase'
import { navigate } from 'gatsby-link';

const DoctorCard = ({doctor}) => {
  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser

  const [isCompleted, setIsCompleted] = useState(doctor.isCompleted)

  // const [doctorModal, setComplete] = useState(false)
  // const [isVerified, setIsVerified] = useState(user.emailVerified)
  // const [emailSent, setEmailSent] = useState(false)

  const [doctorModal, setDoctorModal] = useState(false)

  const showDoctorModal = () => {
    setDoctorModal(true)
  }

  const closeDoctorModal = () => {
    setDoctorModal(false)
  }

  // const setIsComplete = () => {
  //   setIsCompleted(true)
  // }

  const handleAppointment = () =>{

    user
    ?
      navigate("/app/scheduler", {state:{doctor},})
    :
      navigate("/signin")
  }

  // const handleProfile = (doctor) =>{
  //   navigate(`/doctores/${doctor.uid}`)
  // }

  return (
    <div className="doctor-container">
      <Image cloudName="bizhightech" publicId={doctor.img.public_id} className="doctor-image">
        <Placeholder type="blur" />
        <Transformation width="400" height="220" gravity="faces" crop="fill" radius="10" />
        <Transformation quality="auto" fetchFormat="auto" /> 
        <Transformation overlay="allodoc" gravity="south_east" x="45" y="15" width="50" opacity="50" effect="brightness:200" />
      </Image>
      {/* <img src={doctor.image} alt={doctor.fullName} className="doctor-image" /> */}
      <h4 className="doctor-name color-3">{doctor.isDoctor&&<span>Dr{""}</span>}{`${doctor.firstname} ${doctor.lastname}`}</h4>
      <h5 className="doctor-practice color-3">{doctor.spec}</h5>
      <p className="doctor-summary color-3">{doctor.summary}</p>
      <div className="doctor-cta">
        <button onClick={handleAppointment} className="mybtn color-3 color-0-bg">Rendez-vous</button>
        <button onClick={showDoctorModal} className="mybtn color-0 color-3-bg right">Voir Profil</button>
      </div>
      {doctorModal && <DoctorModal doctor={doctor} visible={doctorModal} close={closeDoctorModal} />}
    </div>
  );
};

export default DoctorCard;