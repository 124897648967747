import React, {useState, useEffect, useContext} from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import HeaderDocs from "../components/headerDocs"
import DoctorList from "../components/doctorList"
import { FirebaseContext } from '../firebase'
import HeaderMedPros from "../components/headerMedPros"


const Doctors = () => {

  const [doctors, setDoctors] = useState([])
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    getDoctors()
  }, [])

  const getDoctors = () =>{
    firebase.db.collection('doctor').where("isMedPro", "==", true).where("isCompleted", "==", true).where("isActive", "==", true).orderBy("lastname", "asc")
    .onSnapshot((snap) => {
      const doclist = []
      snap.forEach(doc => {
        doclist.push({...doc.data()})
      });
      setDoctors(doclist)
    })
  }

  

  return(
  <Layout>
    <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
    {/* <HeaderDocs /> */}
    <HeaderMedPros />
    <div className="home-body">
      <DoctorList doctors={doctors} />
    </div>
  </Layout>
)}

export default Doctors