import React from 'react';
import Col from 'react-materialize/lib/Col';
import Row from 'react-materialize/lib/Row';
import DoctorCard from './doctorCard';

const DoctorList = ({doctors}) => {


  return (
    <section className="color-4-bg">
      <div className="container1">      
        <Row>
          {
            doctors.map(doctor => {
              return(
                <Col l={4} m={6} s={12} key={doctor.uid}>
                  <DoctorCard doctor={doctor} />
                </Col>
              )
            })
          }
        </Row>
      </div>
    </section>
  );
};

export default DoctorList;